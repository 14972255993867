<template>
  <b-img :src="flag" :width="width"></b-img>
</template>

<script>
import { BImg } from "bootstrap-vue";

export default {
  name: 'countryFlagSvg',
  props: {
    country: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "20"
    }
  },
  components: {
    BImg
  },
  data () {
    return {
      flag: require(`@/assets/images/countries/${this.country.toLowerCase()}.svg`)
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

